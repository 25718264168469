import React, {useContext} from "react";
import "./WorkExperience.scss";
import "./Modal.scss";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import {workExperiences} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import {useModal} from "../../hooks/useModal";
import ReactModal from "react-modal";
import ReactMarkdown from "react-markdown";

export default function WorkExperience() {
  const {isDark} = useContext(StyleContext);
  const modal = useModal();
  const basePath = process.env.PUBLIC_URL;

  if (workExperiences.display) {
    return (
      <div id="experience">
        <ReactModal
          isOpen={modal.isOpen}
          onRequestClose={modal.closeModal}
          contentLabel="Experience Modal"
          className="modal-content"
          overlayClassName="modal"
          preventScroll={true}
        >
          <ReactMarkdown
            children={modal.text}
            transformImageUri={uri =>
              uri.startsWith("http") ? uri : `${basePath}${uri}`
            }
            components={{
              img: props => (
                <img
                  src={props.src}
                  alt={props.alt}
                  style={{
                    display: "block",

                    margin: "1rem auto",
                    maxWidth: "500px",
                    height: "auto"
                  }}
                  {...props}
                />
              )
            }}
          />
        </ReactModal>
        <Fade bottom duration={1000} distance="20px">
          <div className="experience-container" id="workExperience">
            <div>
              <h1 className="experience-heading">Experiences</h1>
              <div className="experience-cards-div">
                {workExperiences.experience.map((card, i) => {
                  return (
                    <ExperienceCard
                      key={i}
                      isDark={isDark}
                      cardInfo={{
                        company: card.company,
                        desc: card.desc,
                        date: card.date,
                        companylogo: card.companylogo,
                        role: card.role,
                        descBullets: card.descBullets,
                        markdownPath: card.markdownPath
                      }}
                      setText={modal.setText}
                      openModal={modal.openModal}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
  return null;
}

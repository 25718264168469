import {useState} from "react";

export const useModal = () => {
  const [text, setText] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return {
    text,
    isOpen,
    setText,
    openModal,
    closeModal
  };
};
